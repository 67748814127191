module.exports = [
  'Draft',
  'Feasibility',
  'SC_Review_Pending',
  'Vote_Pending',
  'Approved',
  'Implemented',
  'Rejected',
  'Deprecated',
]
